import React, { useState } from 'react'
import styled from 'styled-components'

export default function fourOhFour() {
  return (
    <div>
      404!
    </div>
  )
}
